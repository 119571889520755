<!--опора для саб меню-->
<div class="top-parent">

</div>
<div class="wrapp">
    <ul class="list">
      <!-- Перебираем категории -->
      <ng-container *ngFor="let category of menuData">
        <li class="list__element" (mouseenter)="showSubMenu(category)" (mouseleave)="hideSubMenu($event)">
          <!-- Если есть href, добавляем ссылку -->
          <a *ngIf="category.href" class="list__title" [routerLink]="category.href">{{ category.category }}</a>
          
          <!-- Если нет href, просто отображаем название категории -->
          <span *ngIf="!category.href" class="list__title">{{ category.category }}</span>
    
          <!-- Добавляем иконку-галочку -->
          <img *ngIf="category.items" class="list__icon" src="../../../assets/header/right-arrow.svg"/>
    
          <!-- Отображаем подменю, если оно активно для текущей категории -->
          <app-header-menu-list *ngIf="category.showSubMenu"
            [items]="firstColumnData" 
            [items2]="secondColumnData"
            [items]="category.items"
            [items2]="category.items2"
            [image]="category.image" 
            [imageCategory]="category.imageCategory"
            [title]="category.title" 
            [discription]="category.discription"
            [link]="category.link">
         </app-header-menu-list>
        </li>
      </ng-container>
    </ul>
  </div>
  

