// app-packages-tab.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { SiteService } from 'src/app/site.service';
import { openFormService } from 'src/app/servises/openForm.service';
@Component({
  selector: 'app-packages-tab',
  templateUrl: './packages-tab.component.html',
  styleUrls: ['./packages-tab.component.scss'], 
}) 
export class PackagesTabComponent implements OnInit {
  @Input() item: any[] = [];
  @Input() page:any = {};
  activeIndex = 0;
  isBlockVisible: boolean[][] = [];


   constructor(
      private siteService: SiteService,
      private openFormService: openFormService
    ) {}


  ngOnInit() {
    this.item.forEach((pkg) => {
      const visibilityArray = new Array(pkg.blocks.length);
      visibilityArray[0] = true; // Первый блок всегда открыт
      visibilityArray.fill(false, 1); // Остальные блоки закрыты

      this.isBlockVisible.push(visibilityArray);
    });
  }
 
  setActive(index: number) {
    this.activeIndex = index;
  } 

  toggleHidden(blockIndex: number) {
    // Закрываем все блоки, кроме текущего
    for (let i = 0; i < this.isBlockVisible[this.activeIndex].length; i++) {
      if (i === blockIndex) continue;
      this.isBlockVisible[this.activeIndex][i] = false;
    }
  
    // Меняем состояние текущего блока
    this.isBlockVisible[this.activeIndex][blockIndex] = !this.isBlockVisible[this.activeIndex][blockIndex];
  }


  onButtonClick(pack: any) {
    this.openFormService.backgroundTop(pack.formContent);
  }
}


