
<div style="width: 90%; margin: auto; margin-bottom: 100px;">
   <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
</div> 

<div style="width: 90%; margin: auto;">
  <app-element-product-text
   [item]="this.page.text1"> 
  </app-element-product-text>
</div>

   <div style="width: 90%; margin: auto; margin-top: 100px;">
    <app-element-product-baner
        [item]="this.page.baner">
    </app-element-product-baner>
  </div> 

  <div style="width: 90%; margin: auto;">
     <app-element-product-text *ngIf="this.device == undefined"
        [item]="this.page.text1">
     </app-element-product-text> 
  </div>

  <div style="width: 90%; margin: auto;">
     <app-element-product-text *ngIf="this.device == undefined"
        [item]="this.page.text2">
     </app-element-product-text>
  </div>

  <div style="width: 90%; margin: auto;">
     <app-element-product-text *ngIf="this.device == undefined"
        [item]="this.page.text3">
     </app-element-product-text>
  </div>

  <div style="width: 75%; margin: auto; margin-top:80px;">
    <app-element-tags [item]='[
         { text:"Модуль бронирования + Битрикс24"},
         { text:"Обучение Битрикс24"},
         { text:"1С:Отель + Битрикс24"},
         { text:"Лицензии Битрикс24"},
         { text:"SIP телефония для Битрикс24"},
         { text:"Сопровождение Битрикс24"},
         { text:"Дополнительные воронки продаж Битрикс24"},
       ]'>
       </app-element-tags>
   </div>
   <div style="width: 90%; margin: auto; margin-top: 100px; margin-bottom: 100px;">
   <app-element-product-item *ngIf="this.device != undefined" [item]="this.device"></app-element-product-item>
  
   <app-element-product-list *ngIf="this.device == undefined" [item]="this.products">

   </app-element-product-list>

   </div>  

   <div style="width: 90%; margin: auto; margin-top: 100px; margin-bottom: 100px;">
      <app-mini-banner *ngIf="this.device == undefined" [item]="this.page.baner2">

      </app-mini-banner>
   </div>

 
   <div style="width: 90%; margin: auto; margin-top: 100px; margin-bottom: 100px;">
       <app-element-baner-form [item]="this.page.forms"></app-element-baner-form>
   </div>

   <div style="margin-top: 100px;">
      <app-footer></app-footer> 
    </div> 


