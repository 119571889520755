import { Component, Input } from '@angular/core';
import { SiteService } from '../site.service'; // Предположительно, сервис с данными меню
@Component({
  selector: 'app-header-menu-list',
  templateUrl: './header-menu-list.component.html',
  styleUrls: ['./header-menu-list.component.scss']
})
export class HeaderMenuListComponent {
  public mainMenu: any[] = [];


  @Input() items: any[] = [];
  @Input() image: string = '';
  @Input() imageCategory: string = '';
  @Input() title: string = '';
  @Input() discription: string = '';
  @Input() link: string = '';

  //вывод данных для правой колонки
  @Input() items2: any[] = [];

  constructor(private siteService: SiteService) {}

  ngOnInit(): void {
    this.mainMenu = this.siteService.mainMenu; // Получение данных из сервиса
  }

  hasItems2(): boolean {
    return this.items2 && this.items2.length > 0; // Возвращает true, если items2 не пустой массив
  }
}
