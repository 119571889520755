import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup-information',
  templateUrl: './popup-information.component.html',
  styleUrls: ['./popup-information.component.scss']
})
export class PopupInformationComponent {
  @Input() href:any = "";
}
