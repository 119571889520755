<div *ngFor="let attention of item.attention" class="block-text__description">
    <img class="block-text__icon" src="../../../assets/services/warnin.svg" />
    <div *ngIf="attention.text != undefined">
      <div *ngIf="attention.href == undefined" [class.bold]="attention.bold == true">
        <p class="span-discription">{{ attention.text }}</p>
        <h4 class="conteiner-top__text-content-price">
          <p class="conteiner-top__text-content">{{ attention.text1 }}</p>
          {{ attention.text2 }}
          <h5 class="conteiner-top__text-content-price-sale">
            {{ attention.text3 }}
            <p class="conteiner-top__text-content-price-new">{{ attention.text4 }}</p>
          </h5>
        </h4>
      </div>
    </div>
  </div>
  
