<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average ></app-interval-average>
</div>

<div class="section-width-90">
   <app-element-breadcrumbs [item]="this.page.breadcrumbs" ></app-element-breadcrumbs>
</div> 


<div class="section-width-100">
   <app-interval-average *ngIf="this.device == undefined"></app-interval-average>

   <div class="section-width-90">
      <app-element-product-text *ngIf="this.device == undefined"
       [item]="this.page.text1">  
      </app-element-product-text> 
    </div>


    <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
    <div class="section-width-75">
       <app-element-tags *ngIf="this.device == undefined" [item]='[
             { text:"Модуль бронирования + Битрикс24", href:"developments/booking-module", },
          
           ]'>
           </app-element-tags>
           <!--   { text:"Обучение Битрикс24"},
             { text:"1С:Отель + Битрикс24"},
             { text:"Лицензии Битрикс24"}, 
             { text:"SIP телефония для Битрикс24"},
             { text:"Сопровождение Битрикс24", href:"developments/booking-module"},
             { text:"Дополнительные воронки продаж Битрикс24"},-->
       </div>

   <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
    <div class="section-width-100">
      <app-banner-partner [page]="this.page" [item]="this.page.banerOne" ngSkipHydration  *ngIf="this.device == undefined"></app-banner-partner>
    </div>

 
      <app-interval-average></app-interval-average>
      <div class="section-width-90">
         <app-element-product-item *ngIf="this.device != undefined" [item]="this.device"></app-element-product-item>
        
         <app-element-product-list *ngIf="this.device == undefined" [item]="this.products">
      
         </app-element-product-list>
       
      </div>  

    <!-- <app-interval-big></app-interval-big>
      <div class="section-width-100">
         <app-banner-partner [item]="this.page.banerOne" ngSkipHydration></app-banner-partner>
      </div>--> 

      <app-interval-big></app-interval-big>
      <div class="section-width-100">
         <app-element-baner-form [item]="this.page.forms"></app-element-baner-form>
      </div>


     <app-interval-big></app-interval-big>
     <div class="section-width-100">
        <app-inputs-forms></app-inputs-forms>
    </div> 

   
     <div class="section-width-100">
      <app-footer></app-footer> 
    </div> 
</div>


  


    <div >
      <app-modal-forms ></app-modal-forms>
    </div>

 