<div class="text-content__contenier">
    <p class="text-content__text">{{this.item.texts.contentText}}</p>
    <h3 class="text-content__title">{{this.item.texts.contentTitle}}</h3>
    <p class="text-content__text">{{this.item.texts.contentText2}}</p>
    <ul class="text-content__list">
        <li class="text-content__list-text">{{this.item.texts.contentlistText}}</li>
    </ul>
    <h3 class="text-content__title">{{this.item.texts.contentTitle2}}</h3>
    <p class="text-content__text">{{this.item.texts.contentText3}}</p>
    <ul class="text-content__list">
        <li class="text-content__list-text">{{this.item.texts.contentlistText2}}</li>
    </ul>
    <p class="text-content__text">{{this.item.texts.contentText4}}</p>
</div>
