import { Component, Output, EventEmitter, Input, Renderer2, ViewChild, ElementRef, OnInit, QueryList, ViewChildren, AfterViewInit  } from '@angular/core';
import { ToggleService } from '../../servises/toggle.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VisibilityService } from '../../servises/Visibility.service'; // Импортируем созданный ранее сервис
import { SiteService } from '../../site.service';
import { Subscription } from 'rxjs';
import { openFormService } from '../../servises/openForm.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-modal-form2-upper',
  templateUrl: './modal-form2-upper.component.html',
  styleUrls: ['./modal-form2-upper.component.scss']
})
export class ModalForm2UpperComponent {

  
    @Output() removeUpper = new EventEmitter<void>();
    @Input() item: any = {};
    public currentPage: any = undefined;
    @Input() inputTitle: any = [];
    @ViewChildren('refItem1') refItems!: QueryList<ElementRef>;
    subscription!: Subscription;
    @Input() products: any = undefined;
    @ViewChild('iconTitleCont') iconTitleCont!: ElementRef<HTMLElement>; 
  
    // Переменная для хранения последнего активного элемента
    private lastActiveControlDiv: HTMLElement | null = null;
    private serverUrl = 'http://localhost:4200';
  
    // Свойство должно быть доступно и инициализировано
    phone: string | undefined;
    client:any = {
      name:"",
      phone: "",
      email: "",
      comment: "",
    };
  
  
    constructor(
      private openFormService: openFormService,
      private renderer: Renderer2,
      private toggleService: ToggleService,
      private visibilityService: VisibilityService,
      public site: SiteService,
      private elementRef: ElementRef,
      private http: HttpClient
    ) {
      this.phone = '';
  
      
    }
  
    onCheckName(event:any){
      this.client.name = event.target.value;
    }
    onCheckPhone(event:any){
      this.client.phone = event.target.value;
    }
    onCheckEmail(event:any){
      this.client.email = event.target.value;
    }
    onCheckComment(event:any){
      this.client.comment = event.target.value;
    }
    checkInputValue(event: Event) {
      const target = event.target as HTMLInputElement;
      const isEmpty = !target.value.trim().length;
  
      if (isEmpty) {
          if (this.iconTitleCont?.nativeElement) {
              this.renderer.removeClass(this.iconTitleCont.nativeElement, 'title-active');
          }
      } else {
          if (this.iconTitleCont?.nativeElement) {
              this.renderer.addClass(this.iconTitleCont.nativeElement, 'title-active');
          }
      }
  }
  
    ngOnInit(): void { 
      this.renderer.listen('window', 'click', (e: Event) => {});
  
      this.phoneForm = new FormGroup({
        phoneNumber: new FormControl('', [
          Validators.required,
          Validators.pattern('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$')
        ])
      });
  
      this.openFormService.backgroundChanged.subscribe((item: any) => {
        console.log('visible2', item);
      });
  
      console.log('products', this.products);
  
      // Слушаем событие клика на document
      this.renderer.listen('document', 'click', (event: MouseEvent) => {
        if (!this.isDescendant(event.target as Node, '.control')) {
          // Если клик не внутри .control, удаляем активный класс
          this.removeActiveClasses();
        }
      });
    }
  
    ngAfterViewInit() {
      this.refItems.forEach(item => {
        this.renderer.listen(item.nativeElement.querySelector('.control'), 'click', () => {
          this.toggleActiveClass(item.nativeElement);
        });
      });
    }
  
    /**
     * Проверяет, является ли node потомком элемента с указанным селектором
     */
    isDescendant(node: Node, selector: string): boolean {
      let el = node as HTMLElement;
      while (el && el !== document.body) {
        if (el.classList.contains(selector.slice(1))) { // Убираем точку из селектора
          return true;
        }
        el = el.parentNode as HTMLElement;
      }
      return false;
    }
  
    /**
     * Удаление активных классов со всех элементов .icon-title-cont
     */
    removeActiveClasses() {
      this.refItems.forEach(item => {
        const titleContDiv = item.nativeElement.querySelector('.icon-title-cont');
        const inputField = item.nativeElement.querySelector('input.input-title');
        
        if (titleContDiv && inputField) {
          const isFilled = !!inputField.value.trim().length; // Проверяем, заполнено ли поле ввода
          
          if (!isFilled) {
            this.renderer.removeClass(titleContDiv, 'title-active');
          }
        }
      });
    }
  
    /**
     * Переключение активного класса для выбранного элемента
     */
    toggleActiveClass(controlDiv: HTMLElement) {
      const iconTitleCont = controlDiv.querySelector('.icon-title-cont');
      const inputField = controlDiv.querySelector('input.input-title');
  
      if (iconTitleCont && inputField) {
        if (this.lastActiveControlDiv !== controlDiv) {
          this.removeActiveClasses(); // Удаляем активный класс со всех элементов
          this.renderer.addClass(iconTitleCont, 'title-active'); // Добавляем класс title-active к .icon-title-cont
          this.lastActiveControlDiv = controlDiv; // Сохраняем текущий активный элемент
        } else {
          this.renderer.removeClass(iconTitleCont, 'title-active'); // Убираем класс title-active
          this.lastActiveControlDiv = null; // Обнуляем ссылку на последний активный элемент
        }
      }
    }
    
    onClick() {
      if (!this.client.name || !this.client.phone || !this.client.email) {
        alert('Пожалуйста, заполните все обязательные поля.');
        return;
      }
      var body = {
        subject: "Заказ на сайте",
        product: this.products,
        client: this.client,
      }
      console.log({body});
      this.http.post(this.serverUrl + '/api/send-email', body).subscribe(
        (res) => {
          console.log(res);
        }, (err) => {
          console.log(err);
      })
  
      //this.sendEmail(this.products);
      this.removeUpper.emit();
      this.visibilityService.triggerHide(); // Вызываем метод сервиса для отправки
      this.toggleService.showLowerThenAnimate(); // Используем новый метод для последовательности действий
    }
    phoneForm!: FormGroup;
    ngAfterViewCheckedError(error: Error) {
      console.error(`ngAfterViewCheckedError: ${error.message}`);
    }
   onKeyUp(event: KeyboardEvent) {
      const target = event.target as HTMLInputElement;
      let value = target.value.replace(/[^0-9]/g, ''); // Убираем все символы кроме цифр
  
      if (value.length === 10 || value.length === 11) {
        value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
      } else if (value.length >= 12) {
        value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
  
      target.value = value;
    }

}
