<div class="wrapp">
    <!-- Этот блок исчезает при скролле вниз и появляется при возврате к началу -->
  <div class="wrapp-top-menu" [class.hidden]="isHidden">
    <app-header-menu-top></app-header-menu-top>
    <app-header-menu-bottom [menuData]="mainMenu"></app-header-menu-bottom>
  </div>
  <div class="wrapp-bottom-menu" [class.sticky]="isSticky" *ngIf="isScrolled">
    <!-- Этот блок видим и содержит кнопку открытия и закрытия app-header-menu-bottom -->
    <div class="bottom-menu__visible" [ngClass]="{'fadeOut': shouldHideList}">
      <div class="left-cont">
         <a href="#"><img src="assets/main/Logo%20red.svg" class="left-cont__img"/></a>
         <p class="left-cont__title">КОМПЛЕКС РЕШЕНИЙ ДЛЯ ОТЕЛЕЙ</p>
      </div>
      <div class="searh-cont">
         <app-search-dark></app-search-dark>
      </div>
      <div class="riht-cont">
         <div class="riht-cont__content">
            <img class="riht-cont__icon" src="../../../assets/icons/icon4.svg" alt="like"/>
            <p class="riht-cont__text">Бесплатная консультация</p>
         </div>
            <a class="riht-cont__text riht-cont_pointer" href="tel:+1234567890">8 (861) 213-23-13</a>
            <a class="riht-cont__text riht-cont_pointer" href="mailto:acc@tripadvance.ru">acc@tripadvance.ru</a>
      </div>
      <div class="content">
         <div class="hamburger" (click)="toggleBottomMenu()" [class.active-hum]="showBottomMenu">
           <span class="bar"></span>
           <span class="bar"></span>
           <span class="bar"></span>
         </div>
      </div>
      
    </div>
    <!-- Этот блок становится видимым только после клика на кнопку -->
    <app-header-menu-bottom *ngIf="showBottomMenu" [menuData]="mainMenu"></app-header-menu-bottom>
  </div>
</div>

<div class="menu-mobile">
  <div class="wrapp-bottom-menu__mobile">
    <!-- Этот блок видим и содержит кнопку открытия и закрытия app-header-menu-bottom -->
    <div class="bottom-menu__visible">
      <div class="left-cont">
         <a class="" href="#">
          <img src="assets/main/Logo%20red.svg" class="left-cont__img"/>
        </a>
         <p class="left-cont__title">КОМПЛЕКС РЕШЕНИЙ ДЛЯ ОТЕЛЕЙ</p>
      </div>
      <div class="searh-cont">
         <app-search-dark></app-search-dark>
      </div>
      <div class="riht-cont">
         <div class="riht-cont__content">
            <img class="riht-cont__icon" src="../../../assets/icons/icon4.svg" alt="like"/>
            <p class="riht-cont__text">Бесплатная консультация</p>
         </div>
            <a class="riht-cont__text riht-cont_pointer" href="tel:+1234567890">8 (861) 213-23-13</a>
            <a class="riht-cont__text riht-cont_pointer" href="mailto:acc@tripadvance.ru">acc@tripadvance.ru</a>
      </div>
      <div class="content">
         <div class="hamburger" [class.active-hum]="isActive" (click)="toggleMenu()">
           <span class="bar"></span>
           <span class="bar"></span>
           <span class="bar"></span>
         </div>
      </div>
      
    </div>
    
  </div>
    <app-header-menu-rotate *ngIf="isMenuMobileOpen" [class.open]="isMenuMobileOpen" (closedMenu)="onClosedMenu()"></app-header-menu-rotate>
</div>



