import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-packages',
  templateUrl: './element-packages.component.html', 
  styleUrls: ['./element-packages.component.scss']
})
export class ElementPackagesComponent implements OnInit {
  @Input() item: any[] = [];
  activePacket: number = 0;  
  isInit: boolean = false; 

  constructor(
    public route: ActivatedRoute,
    private openFormService: openFormService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['packet'] !== undefined) {
        this.activePacket = parseInt(params['packet']); 
      }
    });

    this.isInit = true;
  }

  ngAfterViewInit(): void {
    // Здесь можно оставить пустой метод, если он необходим для других целей
  }

  changeBlock(blocks: any[], item: any) {
    blocks.forEach((b: any) => b.open = false);
    const foundIndex = blocks.findIndex((b: any) => b === item);
    if (foundIndex >= 0) {
      blocks[foundIndex].open = !blocks[foundIndex].open;
    }
  }

  toPrice(price: any) {
    return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ');
  }

  isOpen = false;

  onClickButton() {
    this.openFormService.backgroundTop();
  }
}

