
<div class="wrapp">

    <div class="left">
       <div class="tab" *ngFor="let item of this.products" (click)="onChange(item)" [class.actual]="(item==this.currentProduct)">
          <p class="title-product">{{item.button}}</p>
       </div>
    </div>   
    <div class="right">
       <div class="products-contenier" *ngFor="let  product of this.currentProducts">
           <p class="title-product product-discription">{{product.name}}</p>
           <div class="line-vertical"></div>
           <div class="price-link-contenier">
             <p class="product-price">{{product.price}}₽</p>
             <div class="button-contenier">
                <app-button>ЗАКАЗАТЬ</app-button>
             </div>
           </div>
       </div>
    </div>

</div>