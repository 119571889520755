import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-o-nas',
  templateUrl: './o-nas.component.html',
  styleUrls: ['./o-nas.component.scss']
})
export class ONasComponent {
  page:any = {};
// Здесь задаем начальное значение для динамического фона
dynamicBackground = 'url(../../../../assets/pages/o-nas/1.webp)';
  
// Например, ты можешь менять это значение в зависимости от какого-то события
changeBackground(newImagePath: string) {
   this.dynamicBackground = newImagePath;
}
  constructor(public site:SiteService, private route: ActivatedRoute){

  }


  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();
  }

  ngAfterContentInit(){
  }
}
