<div class="wrapper">
    
    <!--<div class="buttons-wrapper desktop">
        <app-button-slider-wihte (onClick)="($event == 1) ? this.productNext() : this.productPrev()"></app-button-slider-wihte>
     </div>-->
    
    <app-slider [buttonsMobile]="true" [type]="this.animationType" [duration]="this.animationDuration" [countView]="1">

        <app-slide class="slide" *ngFor="let item of this.site.elements.bannerAnimation">
  
             <app-element-slides-bottom-animation [item]="item" style="position: relative; width: 100%;">
               
             </app-element-slides-bottom-animation> 
             
         </app-slide>   
          

    </app-slider>





</div>
