
<app-panel  *ngIf="this.isInit" [setPage]="this.activePacket">
    <app-panel-page  *ngFor="let item of this.item"
        [title]="item.title"
        [type]="item.type"
        [image]="item.image"
    > 

        <div  class="packet"> 
            <p  class="discription">{{item.description_detail}}</p>

            <div class="packet-blocks">
                <div class="packet-block-item pointer" *ngFor="let block of item.blocks">
                    <div class="packet-block"  (click)="this.changeBlock(item.blocks, block)">
                        <div *ngIf="block.enabled" class="packet-block__active"></div>
                        <div *ngIf="!block.enabled" class="packet-block__noactive"></div>
                        <p class="packet-block__text" [class.packet-block__text-noactive]="!block.enabled">{{block.name}}</p>
                        <p class="points"></p>
                        <app-arrow  [setOpen]="block.open"></app-arrow>
                    </div>

                 
                     <app-collapse [expanded]="block.open">
                        <h5 [class.packet-block__text-noactive]="!block.enabled">{{block.description}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description2}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description3}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description4}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description5}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description6}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description7}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description8}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description9}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description10}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description11}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description12}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description13}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description14}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description15}}</h5>
                        <h5 [class.packet-block__text-noactive]="!block.enabled" class="margins-title">{{block.description16}}</h5>
                    </app-collapse>
        

                </div>
            </div>
            <p>
                {{item.footer}}
            </p> 

            <!--<div class="packet-separator"></div>-->

            <div class="packet-footer" >
                <div class="packet-footer__item">
                    <p class="packet-footer__price">
                        <app-price [value]="'от ' + item.price" [size]="1.4"></app-price>
                    </p>
                    <div class="separator"></div>
                    
                    <app-button  (click)="this.onClickButton()">{{item.button}}</app-button> 
                  
                </div> 

                <div class="attentions">
                    <div class="attentions-item" *ngFor="let attention of item.attentions">
                        <app-footnote [item]="attention"></app-footnote>
                    </div>
                </div> 
            </div>       
        </div>   
    </app-panel-page>
</app-panel>



