import { Component, OnInit,Input } from '@angular/core';
import { SiteService } from '../../site.service'; 


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html', 
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
   @Input() item:any = {};

  
  constructor(public site: SiteService) {}

  ngOnInit(): void {
   
  }

  
}
