import { Component, Input } from '@angular/core';
import { SiteService } from 'src/app/site.service';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-product-list',
  templateUrl: './element-product-list.component.html',
  styleUrls: ['./element-product-list.component.scss']
})
export class ElementProductListComponent { 
  @Input() item:any = [];
  @Input() products:any = [];
  public currentProduct:any = undefined;
  public currentProducts:any = [];

  constructor(public site:SiteService,){
  }
  ngOnInit(){
    if(this.products.length>0){
      this.currentProduct = this.products[0];
      console.log(this.currentProduct);
      this.currentProducts = this.site.GetProducts(this.currentProduct.products);
      console.log(this.currentProducts)
    }
  }

  onChange(currentProduct:any){
    this.currentProduct = currentProduct;
    this.currentProducts = this.site.GetProducts(this.currentProduct.products);
  }
} 
 