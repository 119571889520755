<div class="wrapp">
    <div class="scrollable-content" *ngFor="let menuItem of mainMenu; let i = index">
        <div style="  border-bottom: 0.5px solid #ffffff82;" *ngIf="shouldDisplayMenuItem(menuItem)" class="category" >
            <div class="category__title" (click)="toggleSubCategory(menuItem, i)">
                {{ menuItem.category }}
                <i class="checkbox" [class]="menuItem.isOpen ? 'checkbox-opened' : ''"></i>
                <img class="category__icon" src="assets/header/right-arrow.svg" [class.icon-rotated]="menuItem.isOpen" />
            </div>
    
            <div *ngIf="menuItem.isOpen" class="subcategory" [class.subcategory-open]="menuItem.isOpen">
                <ul class="open" *ngIf="menuItem.items?.length > 0">
                    <li *ngFor="let subCat of menuItem.items">
                        <img class="category__icon2" src="assets/header/right-arrow.svg" [class.icon-rotated2]="subCat.isShown"/>
                        <span class="close" (click)="toggleList($event, subCat)">{{ subCat.subCategory }}</span>
                       
    
                        <ul class="open list-cont" *ngIf="subCat.isShown">
                            <li *ngFor="let link of subCat.list">
                                <img class="category__icon2" src="assets/header/right-arrow.svg"/>
                                <a [href]="link.href">{{ link.linkName }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
    
                <ul class="open" *ngIf="menuItem.items2?.length > 0">
                    <li *ngFor="let subCat of menuItem.items2">
                        <img class="category__icon2" src="assets/header/right-arrow.svg" [class.icon-rotated2]="subCat.isShown"/>
                        <span class="close" (click)="toggleList($event, subCat)">{{ subCat.subCategory }}</span>
                      
    
                        <ul class="open list-cont" *ngIf="subCat.isShown">
                            <li *ngFor="let link of subCat.list">
                                <img class="category__icon2" src="assets/header/right-arrow.svg"/>
                                <a [href]="link.href">{{ link.linkName }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    
</div>







