import { Component, Input } from '@angular/core';
import { SiteService } from '../../site.service';

@Component({
  selector: 'app-titels',
  templateUrl: './titels.component.html',
  styleUrls: ['./titels.component.scss']
})
export class TitelsComponent {
  @Input() public title:any = "НАША ЦЕЛЬ";
  @Input() public subtitle:any = "awdawd"; 
  @Input() public discription:any = "awdawd"; 
  @Input() public titellink:any = "awdawd"; 
}
