import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-scroll-bar',
  templateUrl: './scroll-bar.component.html',
  styleUrls: ['./scroll-bar.component.scss']
})
export class ScrollBarComponent {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLElement>;
  @ViewChild('customScrollbar') customScrollbar!: ElementRef<HTMLElement>;
  @ViewChild('thumb') thumb!: ElementRef<HTMLElement>;

  ngOnInit(): void {
    this.updateThumbPosition();
    this.scrollContainer.nativeElement.addEventListener('scroll', () => this.updateThumbPosition());
    window.addEventListener('resize', () => this.updateThumbPosition());
  }

  updateThumbPosition() {
    const scrollHeight = this.scrollContainer.nativeElement.scrollHeight;
    const clientHeight = this.scrollContainer.nativeElement.clientHeight;
    const scrollTop = this.scrollContainer.nativeElement.scrollTop;

    if (scrollHeight > clientHeight) {
      const percentScrolled = scrollTop / (scrollHeight - clientHeight);
      this.thumb.nativeElement.style.top = `${percentScrolled * (clientHeight - this.thumb.nativeElement.offsetHeight)}px`;
    }
  }
}
