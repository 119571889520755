import { Component, Input } from '@angular/core';
import { openFormService } from 'src/app/servises/openForm.service';

@Component({ 
  selector: 'app-marcetong-cards-two',
  templateUrl: './marcetong-cards-two.component.html',
  styleUrls: ['./marcetong-cards-two.component.scss']
})
export class MarcetongCardsTwoComponent {
  @Input() item:any = {};
  @Input() page:any = {};

  constructor( private openFormService: openFormService) {}
  
  onButtonClick(cards:any) {
    this.openFormService.backgroundTop(cards.formContent);
  } 
}
     