import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class openFormService {

  public item:any = undefined;
  private _backgroundChangedSubject = new Subject<any>();
  public backgroundChanged = this._backgroundChangedSubject.asObservable();

  private _backgroundChangedSubject2 = new Subject<any>();
  public backgroundChanged2 = this._backgroundChangedSubject2.asObservable();

  private _backgroundChangedSubject3 = new Subject<any>();
  public backgroundChanged3 = this._backgroundChangedSubject3.asObservable();

  constructor() { }

  backgroundTop(item:any = undefined) {
    this.item = item;
    this._backgroundChangedSubject.next(item); // Отправляем сигнал о необходимости сменить фон

  }
  backgroundTop2() {

    this._backgroundChangedSubject2.next(undefined); // Отправляем сигнал о необходимости сменить фон
  }
  backgroundTop3() {

    this._backgroundChangedSubject3.next(undefined); // Отправляем сигнал о необходимости сменить фон
  }
}