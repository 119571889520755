// header.component.ts

import { Component, OnInit, HostListener,  Output, EventEmitter,} from '@angular/core';
import { SiteService } from '../site.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit {
  
  public mainMenu: any[] = [];
  isMenuMobileOpen = false;

  isHidden = false;
  isSticky = false;
  isScrolled = false; // Добавляем новое свойство для отслеживания состояния скролла
  shouldHideList = false; // Новый флаг для управления скрытием списка
  showBottomMenu = false; // Новый флаг для управления раскрытием блока app-header-menu-bottom

  constructor(public site: SiteService) {}

  ngOnInit(): void {
    this.mainMenu = this.site.mainMenu;
  }

  isActive = false;
  toggleMenu() {
    this.isMenuMobileOpen = !this.isMenuMobileOpen;
    this.isActive = !this.isActive;
  }
  onClosedMenu() {
    this.isMenuMobileOpen = false;
    this.isActive = false;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  
    // Проверяем, начали ли мы скроллить вниз
    if (scrollPosition > 0 && !this.isScrolled) {
      this.isScrolled = true;
    }
  
    // Если вернулись к началу страницы, скрываем меню
    if (scrollPosition === 0) {
      this.isScrolled = false; // Сбрасываем флаг isScrolled
      this.shouldHideList = false; // Сбрасываем флаг shouldHideList
      this.showBottomMenu = false; // Скрываем блок app-header-menu-bottom
    }
  
    // Исчезновение верхнего меню
    if (scrollPosition > 50) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
  
    // Фиксация нижнего меню
    if (scrollPosition >= 0) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }
  toggleBottomMenu() {
    this.showBottomMenu = !this.showBottomMenu; // Переключаем состояние видимости блока app-header-menu-bottom
  }
}
