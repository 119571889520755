import { Component, Input } from '@angular/core';
import { SiteService } from 'src/app/site.service';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-uslugi',
  templateUrl: './uslugi.component.html',
  styleUrls: ['./uslugi.component.scss']
})
export class UslugiComponent { 
  @Input() products: any[] = [];
  @Input() products2: any[] = []; // Входной параметр для второго набора данных
  public currentProduct: any = undefined;
  public currentProducts: any = [];
  public currentProduct2: any = undefined; // Текущий продукт для второй колонки
  public currentProducts2: any = []; // Продукты для второй колонки
  @Input() page:any = [];
  constructor(
    public site: SiteService,
    private openFormService: openFormService
  ) {}

  ngOnInit(): void {
    if (this.products.length > 0) {
      this.currentProduct = this.products[0];
      this.currentProducts = this.site.GetProducts(this.currentProduct.products); 
    }

    if (this.products2.length > 0) {
      this.currentProduct2 = this.products2[0]; // Устанавливаем начальный продукт для второй колонки
      this.currentProducts2 = this.site.GetProducts(this.currentProduct2.products); // Получаем продукты для второй колонки
    }
  }

  onChange(currentProduct: any): void {
    this.currentProduct = currentProduct;
    this.currentProducts = this.site.GetProducts(this.currentProduct.products);
  }

  onChangeSecondColumn(currentProduct: any): void { // Метод для переключения продуктов во второй колонке
    this.currentProduct2 = currentProduct;
    this.currentProducts2 = this.site.GetProducts(this.currentProduct2.products);
  }

  onButtonClick(product:any = undefined)  {

   
  console.log("onButtonClick",product)
 
   this.openFormService.backgroundTop(product); // Вызываем метод сервиса для изменения фона
}

}
