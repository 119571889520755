<div class="wrapp">
   <div  class="card" *ngFor="let cards of this.item.cards">
      <img class="icon" *ngIf="cards.image!=undefined" [attr.src]="cards.image"/>
      <div *ngIf="cards.text != undefined"> 
         <h3 class="card-title" >{{cards.text}}</h3>
         <p class="card-discription" >{{cards.discription}}</p>      
      </div>   
     
         <app-button ngSkipHydration class="button" (click)="onButtonClick(cards)">СВЯЗАТЬСЯ</app-button>  

   </div>
</div>      