import { Component, Input } from '@angular/core';
import { openFormService } from 'src/app/servises/openForm.service';

@Component({ 
  selector: 'app-banner-partner', 
  templateUrl: './banner-partner.component.html',
  styleUrls: ['./banner-partner.component.scss']
})
export class BannerPartnerComponent {
  @Input() item:any = {};
  @Input() page:any = {};

  constructor( private openFormService: openFormService) {}
  
  onButtonClick(item: any) {
    this.openFormService.backgroundTop(item.formContent);
  } 
}  
 