<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>

<div class="section-width-100">
    <div class="section-width-90">
        <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
     </div> 
     <app-interval-average></app-interval-average>

     <div class="section-width-90">
        <app-element-product-text *ngIf="this.device == undefined"
         [item]="this.page.text1">
        </app-element-product-text> 
      </div> 

      <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
      <div class="section-width-75" *ngIf="this.device == undefined">
        <app-element-tags [item]='[
          { text:"Модуль бронирования", href:"developments/booking-module",},
          { text:"Обучение 1С:Отель с нуля", href:"training-1c-hotel"},
          { text:"Лицензии", href:"programming/licenzii"},
          { text:"1С + кассы онлайн", href:"devices/cash-registers"},
          { text:"Услуги программиста по 1С: Отель", href:"escort-1c-hotel"},
   
        ]'>
        </app-element-tags>
           <!--{ text:"Модуль бронирования"},
          { text:"Обучение 1С:Отель с нуля"}, 
          { text:"1С:Отель + Битрикс24"},
          { text:"1С: Отель отчеты"},
          { text:"Лицензии"},
          { text:"Программа 1С: Отель"},
          { text:"1С + кассы онлайн"},
          { text:"Разработка печатных форм"},
          { text:"Услуги программиста по 1С: Отель"},-->
      </div>

      <app-interval-big *ngIf="this.device == undefined"></app-interval-big>
   
      <div class="hi" *ngIf="this.device == undefined">
        <h1 class="hi__title">ОЗНАКОМТЕСЬ С ОСНОВНЫМИ ПРЕИМУЩЕСТВАМИ <span style="font-weight: 500;" class="hi__title">1С:ОТЕЛЬ</span></h1>
        <p class="hi__subtitle">На сегодняшний день возможности PMS системы - полностью закрывают все задачи в сфере размещения гостей</p>
      </div>

      <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
      <div class="section-width-90">
         <app-cards-rotate   [items]="page.cardsRotate" *ngIf="this.device == undefined"></app-cards-rotate>
      </div>

      <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
      <div class="section-width-100">
         <app-disripton-banner-row *ngIf="this.device == undefined"
           [title]="'ВМЕСТЕ С КОМАНДОЙ TRIPADVANCE'"
           [subtitle]="'Ответим на любые вопросы касаемо 1С: Отель, поможем с подбором и настройкой оборудования, затронем ФМС и бухгалтерию'"
           [title2]="'ВОЗМЕМ НА СЕБЯ ВНЕДРЕНИЕ И СОПРОВОЖДЕНИЕ'"
           [subtitle2]="'1С: Отель - это модульный взаимосвязанный организм с множеством расширений, настроек, и отчетов. Для работы в программе требуется определенный опыт и навыки сотрудников'"
           [background]="dynamicBackground"
         >
         </app-disripton-banner-row> 
      </div>

      <app-interval-big *ngIf="this.device == undefined"></app-interval-big>
      <div class="section-width-100" *ngIf="this.device == undefined">
         <app-titels 
            [title]="'ПРОГРАММА 1С:ОТЕЛЬ'"
            [subtitle]="'Поможем быстро перейти на РМЅ ІС:Отель, организовать рабочие процессы, обучим сотрудников'"
            [discription]="'Подберем необходимую базовую конфигурацию для работы ПО, установим лицензии.'"
            [titellink]="'Обратиться к специалисту'"
         >
      </app-titels>
      </div>

     <app-interval-mini *ngIf="this.device == undefined"></app-interval-mini>
      <div class="section-width-90"> 
         <app-products-tab *ngIf="this.device==undefined" [pageId]="2"></app-products-tab>
         <app-element-product-item *ngIf="this.device != undefined" [item]="this.device"></app-element-product-item>
      </div>

      <app-interval-mini *ngIf="this.device == undefined"></app-interval-mini>
      <div class="section-width-90">
         <app-element-product-list *ngIf="this.device == undefined" [item]="this.products"></app-element-product-list>
      </div> 

      <app-interval-mini *ngIf="this.device == undefined"></app-interval-mini>
      <div class="section-width-90" *ngIf="this.device == undefined">
         <div style="display: flex; justify-content: center;">
            <a class="pages-link" href="services/programming/licenzii">ПЕРЕЙТИ В РАЗДЕЛ ЛИЦЕНЗИИ</a>
         </div>
        
      </div>
    
      
      <app-interval-big *ngIf="this.device == undefined"></app-interval-big>
      <div class="section-width-100">
         <app-titels *ngIf="this.device == undefined"
            [title]="'ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ ПО ПРОГРАММИРОВАНИЮ В 1С:ОТЕЛЬ'"
            [subtitle]="'Решим не типиповые задачи от печатных форм до бронирования парковки и персонализации карт'"
            [discription]="'Индивидуальная разработка под задачи любой сложности на базе 1С.'"
            [titellink]="'Обратиться к специалисту'"
         >
        </app-titels>
      </div>

        <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
        <div class="section-width-90" *ngIf="this.device == undefined">
         <app-uslugi [products]="this.page.serviseKassa" ></app-uslugi>
        </div>

        <app-interval-big *ngIf="this.device == undefined"></app-interval-big>
        <div class="section-width-100" *ngIf="this.device == undefined">
          <app-titels 
            [title]="'СОПУТСВУЮЩИЕ УСЛУГИ ДЛЯ ПРОГРАММЫ 1С:ОТЕЛЬ'"
            [subtitle]="'Настроем рабочие сервера, проведем отладки и выявим проблемы в работе ПО, возмем на техпоодержку'"
            [discription]="'Обеспечим стабильную работу программы, настроем обмены данных и доступы.'"
            [titellink]="'Обратиться к специалисту'"
          >
         </app-titels>
         </div>

      <app-interval-average *ngIf="this.device == undefined"></app-interval-average>
      <div class="section-width-90" *ngIf="this.device == undefined">
         <app-uslugi [products]="this.page.serviseKassa2"></app-uslugi>
      </div>

      <app-interval-big></app-interval-big>
      <div class="section-width-100">
         <app-element-baner-form [item]="this.page.forms"></app-element-baner-form>
      </div>

      <app-interval-big></app-interval-big>
      <div class="section-width-100">
         <app-footer></app-footer> 
       </div> 
      
</div>
<div  *ngIf="page.formContent">
   <app-modal-forms [item]="page.formContent"></app-modal-forms>
 </div>