import { Component, Input } from '@angular/core';
import { SiteService } from 'src/app/site.service';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-block-products-two',
  templateUrl: './block-products-two.component.html',
  styleUrls: ['./block-products-two.component.scss']
})
export class BlockProductsTwoComponent {
  @Input() products:any = [];
  public currentProduct:any = undefined;
  public currentProducts:any = [];


  constructor(public site:SiteService, private openFormService: openFormService){
  }

  ngOnInit(){
    if(this.products.length>0){
      this.currentProduct = this.products[0];
      console.log(this.currentProduct);
      this.currentProducts = this.site.GetProducts(this.currentProduct.products);
      console.log(this.currentProducts)
    }
  }

  onChange(currentProduct:any){
    this.currentProduct = currentProduct;
    this.currentProducts = this.site.GetProducts(this.currentProduct.products);
  }
  
   onButtonClick(product:any = undefined)  {
     this.openFormService.backgroundTop(product); // Вызываем метод сервиса для изменения фона
  }
  
}
