import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent { 
  page:any = {};
  //открытие формы
  formData: any = null;
  
  constructor(public site:SiteService, private route: ActivatedRoute,){}
 
  ngOnInit(): void {   
    this.page = this.site.GetCurrentPage();

  }
  //открытие формы
  openForm(data: any) {
    this.formData = data;
  }
 
}
