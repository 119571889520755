import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-cards-rotate',
  templateUrl: './cards-rotate.component.html',
  styleUrls: ['./cards-rotate.component.scss']
})
export class CardsRotateComponent {
  @Input() items: any[] = [];

  showDescription = false;

  // Метод для переключения состояния
  toggleDescription() {
    this.showDescription = !this.showDescription;
  }
}
