<div class="wrapper">
    <div class="text-contenier">
        <h2 class="title">ВМЕСТЕ С КОМАНДОЙ TRIPADVANCE</h2>
        <h3 class="subtitle">Результат любой деятельности находится в зависимости от степени эффективности делового партнёрства. Tripadvance открыта к плодотворному сотрудничеству на долговременной основе. Успех нашей деятельности невозможен без участия ведущих лидеров в сфере размещения гостей. Мы готовы к партнёрству в области продвижения программного продукта и сервисов для отелей по всему миру</h3>
    </div>
    <div class="banner-contenier">
        <div class="banner-contenier__img">

        </div>
    </div>
</div>
