<div class="wrapp">
    <div class="top">
        <div class="tilels">
            <p class="packet-block__text">{{item?.title}}</p>
            <p class="packet-block__text">{{item?.type}}</p>
        </div>
    </div> 
    <div class="bottom">
        <p class="discription">{{item.discriptionTop}}</p>

        <div class="bottom-list" *ngFor="let block of item.blocks; let i = index">
            <div class="list__title" (click)="toggleDescription(i)">
                <p class="bottom__title">
                    <span class="bottom__left-dotted" [class.disabled]="block?.open === false">&#8226;</span>
                    {{block.titleBlocks}}
                </p>
                <p class="points"></p>
                <app-arrow [setOpen]="isOpen[i]"></app-arrow>
            </div>
            <div class="list__discription" *ngIf="isOpen[i]">
                <p class="bottom__discription">{{block.discriptionBlocks[0]?.description}}</p>
            </div>
        </div>

        <div class="footnote">
            <p class="footnote__text">{{item.footnote}}</p>
        </div>
    </div>

    <div class="attentions">
        <div class="attentions-item" *ngFor="let block of item.attention; let j = index">
           <img class="block-text__icon" src="../../../assets/services/warnin.svg" />
           <!-- Проверка наличия attentionPrice -->
           <ng-container *ngIf="block.attentionPrice && block.attentionPrice.length > 0; else noAttentionPrice">
               <p class="attentions__text" [class.highlighted]="j === 1">{{block.text}}</p>

               <!-- Дополнительный контент для attentionPrice -->
               <ul class="extra-content">
                  <li class="extra-content__title" *ngFor="let attentionItem of block.attentionPrice">
                      {{ attentionItem.text }}
                      <div class="wrapp__price">
                         <span class="extra-content__price">{{ attentionItem.text1 }} {{ attentionItem.text2 }}</span>
                          <div class="extra-content__amounts">
                             <small class="extra-content__sale" >{{ attentionItem.text3 }}</small>
                             <strong class="extra-content__sale-before">{{ attentionItem.text4 }}</strong>
                          </div>
                      </div>
                    
                  </li>
               </ul>
           </ng-container>

            <!-- Блок для случая отсутствия attentionPrice -->
           <ng-template #noAttentionPrice>
                <p class="attentions__text">{{block.text}}</p>
           </ng-template>
        </div>
     </div> 

    <div class="packet-footer__item">
        <p class="packet-footer__price">
           <app-price [value]="'от ' + item.price" [size]="1.4"></app-price>
        </p>
        <div class="separator"></div>
        <div >
            <app-button   ngSkipHydration (click)="onButtonClick(item)">{{item.button}}</app-button>  
        </div>
          
       </div> 

</div>







