
<div *ngFor="let description of this.item.description"  class="block-text__description">
   
    <img class="block-text__icon" src="../../../assets/services/warnin.svg"/>
    <div *ngIf="description.text != undefined">
        <div  *ngIf="description.href==undefined" [class.bold]="description.bold==true">
            <p class="span-discription">{{description.text}}</p>
            <h4 class="conteiner-top__text-content-price" >{{description.text1}}
                <h5 class="conteiner-top__text-content-price-sale">{{description.text2}}
                    <p class="conteiner-top__text-content-price-new">{{description.text3}}</p>
                </h5></h4>
        </div>
        
    </div>       
    
</div>
