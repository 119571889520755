
<div class="wrapp" >
    <div class="wrapp-content">
        <div class="left-cont">
            <a class="" href="#">
                <img src="assets/main/Logo%20red.svg" class="left-cont__img"/>
            </a>
            <p class="left-cont__title">КОМПЛЕКС РЕШЕНИЙ ДЛЯ ОТЕЛЕЙ</p>
         </div>
         <div class="searh-cont">
            <app-search-dark></app-search-dark>
         </div>
         <div class="riht-cont">
               <p class="riht-cont__text">Наши партнеры</p>
               <p class="riht-cont__text">Обучение</p>
               <div class="riht-cont__content">
                <img class="riht-cont__icon" src="../../../assets/icons/icon4.svg" alt="like"/>
                <p class="riht-cont__text">Бесплатная консультация</p>
             </div>
         </div>
         <div class="content">
                <a class="riht-cont__text riht-cont_pointer riht-cont__text-big" href="tel:+1234567890">8 (861) 213-23-13</a>
                <a class="riht-cont__text riht-cont_pointer riht-cont__text-big" href="mailto:acc@tripadvance.ru">acc@tripadvance.ru</a>
         </div>
       
    </div>
    
</div>


