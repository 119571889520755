
<div class="content-tegs desktop">
    <p class="content-tegs__title">Теги для поиска:</p>
    <div class="content-tegs__items">
        <a *ngFor="let item of this.item" class="content-tegs__item" href={{item.href}}>
            {{item.text}}
        </a>
    </div>
    

</div>