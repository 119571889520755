import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-disripton-banner-row',
  templateUrl: './disripton-banner-row.component.html',
  styleUrls: ['./disripton-banner-row.component.scss']
})
export class DisriptonBannerRowComponent {
  @Input() public title:any = "НАША ЦЕЛЬ";
  @Input() public subtitle:any = "awdawd";
  @Input() public discription:any = "awdawd";

  @Input() public title2:any = "НАША ЦЕЛЬ";
  @Input() public subtitle2:any = "awdawd";
  @Input() public discription2:any = "awdawd";

  @Input() background:string = "url('../../../../assets/pages/o-nas/1.jpg')"
}
