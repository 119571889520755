<!-- Первая группа данных -->
<div class="wrapp first-group">
   <!-- Левая колонка -->
   <div class="left">
     <div class="tab" *ngFor="let item of this.products" (click)="onChange(item)" [class.actual]="(item == this.currentProduct)">
       <p class="title-product-left">{{ item.button }}</p>
     </div>
   </div> 
 
   <!-- Правая колонка -->
   <div class="right">
     <div class="products-contenier" *ngFor="let product of this.currentProducts">
       <div class="products-contenier__left">
         <div class="block-baner__container">
           <div class="block-baner__img">
             <img class="image" [attr.src]="product.image" />
           </div>
         </div>
         <div class="line-vertical__container"> 
           <div class="line-vertical__big"></div>
         </div>
       </div>
       <div class="products-contenier__riht">
         <h3 class="title-product">{{ product.name }}</h3>
         <p class="product-discription">{{ product.description }}</p>
         <p class="product-discription">{{ product.description2 }}</p>
         <p class="product-discription">{{ product.description3 }}</p>
         <p class="product-discription">{{ product.description4 }}</p>
         <p class="product-discription">{{ product.description5 }}</p>
         <div class="prise-contenier">
           <p class="product-price"><span class="product-price">{{ product.price2 }}</span>{{ product.price }}₽</p>
           <div class="line-vertical"></div>
           <app-button ngSkipHydration class="button-link" (click)="onButtonClick(product)">ОФОРМИТЬ</app-button>
         </div>
       </div>
     </div>
   </div>
 </div>
 
 <!-- Вторая группа данных -->
 <div class="wrapp second-group">
   <!-- Левая колонка -->
   <div class="left">
     <div class="tab" *ngFor="let item of this.products2" (click)="onChangeSecondColumn(item)" [class.actual]="(item == this.currentProduct2)">
       <p class="title-product-left">{{ item.button }}</p>
     </div>
   </div>
 
   <!-- Правая колонка --> 
   <div class="right">
     <div class="products-contenier" *ngFor="let product of this.currentProducts2">
       <div class="products-contenier__left">
         <div class="block-baner__container">
           <div class="block-baner__img">
             <img class="image" [attr.src]="product.image" />
           </div>
         </div>
         <div class="line-vertical__container">
           <div class="line-vertical__big"></div>
         </div>
       </div>
       <div class="products-contenier__riht">
         <h3 class="title-product">{{ product.name }}</h3>
         <p class="product-discription">{{ product.description }}</p> 
         <p class="product-discription">{{ product.description2 }}</p>
         <p class="product-discription">{{ product.description3 }}</p>
         <p class="product-discription">{{ product.description4 }}</p>
         <p class="product-discription">{{ product.description5 }}</p>
         <div class="prise-contenier">
           <p class="product-price"><span class="product-price">{{ product.price2 }}</span>{{ product.price }}₽</p>
           <div class="line-vertical"></div>
           <app-button ngSkipHydration class="button-link" (click)="onButtonClick(product)">ОФОРМИТЬ</app-button>
         </div>
       </div>
     </div>
   </div>
 </div>  