<div class="wrapper">
    <div class="header">
        <div class="header-image">
            <img class="header-image__img" [attr.src]="this.item.image"/>
        </div>
        <div class="header-image__gallery">
            <img class="header-image__img-gallery" [attr.src]="this.item.galleryImage"/>
            <img class="header-image__img-gallery" [attr.src]="this.item.galleryImage2"/>
        </div>
        <div class="right">
            <div class="header-title">
                <!--<p class="header-title__komp">Комплектация</p>-->
                <div class="header-title__contenier-text">
                  <p class="header-title__title">{{this.item.name}}</p>
                  <p class="header-title__code">{{this.item.id}} КОД</p>
                </div>
                <ul class="list-contenier">
                    <ng-container *ngFor="let row of this.item.hrefs">
                        <li *ngIf="row.akciya != undefined" class="list-contenier__link">
                            <img class="list-contenier__link-img" [attr.src]="row.image"/>
                            <h5 class="list-contenier__title">{{row.akciya.title}}
                                <a style="font-size: 10px" [attr.href]="row.href" class="list-contenier__href" >
                                    {{row.title}}
                                </a>
                            </h5>
                        </li> 

                        <li *ngIf="row.akciya == undefined" class="list-contenier__link-letter">
                            <img class="list-contenier__link-img-letter" [attr.src]="row.image"/>
                            <a style="font-size: 12px" [attr.href]="row.href" class="list-contenier__href">{{row.title}}</a>
                        </li>

                    </ng-container>




                </ul>
            </div>
            
        </div>
    </div>

    <!--<div class="list">
        <div class="list-caption">
            <div class="list-caption-price">
                <p class="list-caption__text">Итого</p>
                <app-price [value]="this.total" [size]="24"></app-price>
            </div>
            <div class="list-caption-button">
                <app-button>Оформить</app-button>
            </div>
            
        </div>

        <div class="list-row">
            <p class="list-row__active">  
                <app-round-span></app-round-span>
                {{this.item.name}}</p>
            <div  class="list-row__right">
                <app-spinner [value]="this.item.count" [min]="1" (onChange)="this.item.count = $event; this.updateTotal()"></app-spinner>
                <app-price class="list-row__right-price" [value]="this.item.price" [size]="16"></app-price>
            </div>
        </div>

        <div class="list-row" *ngFor="let kit of this.kit">
            <p class="list-row__discription">
                <app-round-span></app-round-span>
                {{kit.name}}</p>
            <div  class="list-row__right">
                <p class="list-row__right-discription"></p>
                <app-spinner [min]="0" [value]="kit.count" (onChange)="kit.count = $event; this.updateTotal()"></app-spinner>
                <app-price class="list-row__right-price" [value]="kit.price" [size]="16"></app-price>
            </div>          
        </div>
    </div>


    <div class="list">
        <div class="list-caption">
            <p class="list-caption__text">ХАРАКТЕРИСТИКИ</p>
        </div>

        <div (click)="this.openParam(param)" class="list-row pointer" *ngFor="let param of this.parameters">
            <div class="list-column">
                <div class="list-row__item">
                    <p class="list-row__title">{{param.title}}</p>
                    <p class="points"></p>
                    <div  class="list-row__right">
                        <p>{{param.value}}</p>
                        
                    </div>
                    <app-arrow [setOpen]="param.open"></app-arrow>
                </div>
                <app-collapse [expanded]="param.open">
                    <p>{{param.description}}</p>
                </app-collapse>
            </div>

        </div>
    </div>-->

<div class="list-top">
    <div class="list-top__prise">
        <p class="list-top__title">Итого: 2 позиции</p>
        <app-price-top class="list-top__title-prise" style="flex-direction: column;" [value]="this.total" [size]="24"></app-price-top>
    </div>
    <app-button class="list-top__title-button">ОФОРМИТЬ</app-button>
</div>


<div class="list-bottom">
    <h3 style="font-size: 16px;" class="list-bottom__title">ВЫБИРИТЕ КОМПЛЕКТАЦИЮ</h3>
    <ul class="list">
        <li class="list-product">
            <ul class="list-product__with">
                <li class="list-product__row">
                  <app-round-span class="list-product__round-span"></app-round-span>
                  <p class="list-product__title list-product__title_active">{{this.item.name}}</p><!--list-product__title_active при нажатии на input срабатывает этот класс-->
                  <app-popup-information></app-popup-information>
               </li>
            </ul>
            <ul class="list-product__with2">
                <li class="list-product__price-spiner-contenier">
                   <app-spinner class="list-row__right-spinner" [value]="this.item.count" [min]="1" (onChange)="this.item.count = $event; this.updateTotal()"></app-spinner>
                </li>
                <li class="list-product__price-contenier">
                   <app-price class="list-row__right-price" [value]="this.item.price" [size]="16"></app-price>
                </li>
            </ul>
           
         </li>
       </ul>

  <ul class="list" *ngFor="let kit of this.kit">
    <li class="list-product">
        <ul class="list-product__with">
            <li class="list-product__row">
              <app-round-span class="list-product__round-span"></app-round-span>
              <p class="list-product__title">{{kit.name}}</p><!--list-product__title_active при нажатии на input срабатывает этот класс-->
              <app-popup-information></app-popup-information>
           </li>
        </ul>
        <ul class="list-product__with2">
            <li class="list-product__price-spiner-contenier">
               <app-spinner class="list-row__right-spinner" [min]="0" [value]="kit.count" (onChange)="kit.count = $event; this.updateTotal()"></app-spinner>
            </li>
            <li class="list-product__price-contenier">
               <app-price class="list-row__right-price" [value]="kit.price" [size]="20"></app-price>
            </li>
        </ul>
     </li>
   </ul>    
</div>

<div class="list2">
    <div class="list-caption">
        <p class="list-caption__text">ОПИСАНИЕ И ХАРАКТЕРИСТИКИ</p>
    </div>

    <div  class="list-row2 pointer" ><!--(click)="this.openParam(param)" *ngFor="let param of this.parameters"-->
        <div class="list-column">
            <div class="list-row__item">
                    <p class="list-row__text">{{this.item.description}}</p>
                    <div class="list-row__hrefs" *ngFor="let download of this.item.download">
                         <a class="list-row__href" [attr.href]="download.href">Скачать инструкцию</a>
                         <img class="list-row__img" src="assets/icons/PDF.svg"/>
                    </div>
                    <div *ngFor="let param of this.parameters" class="list-row__parameters">
                         <p class="list-row__parameters-title">{{param.title}}</p>
                         <p class="points"></p>
                         <p class="title-value">{{param.value}}</p>
                    </div>
               
               <!-- <p class="list-row__title">{{param.title}}</p>
                <p class="points"></p>
                <div  class="list-row__right">
                    <p>{{param.value}}</p>
                    
                </div>
                
            </div>
            <app-collapse [expanded]="param.open">
                <p>{{param.description}}</p>
            </app-collapse>-->
        </div>

    </div>
</div>
