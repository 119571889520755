import { Component, Input, Output, EventEmitter } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';
@Component({
  selector: 'app-paralel-scroll',
  templateUrl: './paralel-scroll.component.html', 
  styleUrls: ['./paralel-scroll.component.scss']
})
export class ParalelScrollComponent {
  @Input() item: any = {};

  constructor(private openFormService: openFormService) {}

  ngOnInit(): void {}

  onButtonClick(formContent: any) {
    if (formContent) {
      this.openFormService.backgroundTop(formContent);
    }
  }
}

 