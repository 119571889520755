<div class="wrapp">
    <div class="left">
        <div class="left__content">
          <img class="left__img" *ngIf="image" [src]="image" alt="{{ title }}"/>
          <h2 class="left__title">{{ title }}</h2>
          <p class="left__discription">{{ discription }}</p>
          <a *ngIf="link" [href]="link" class="arrow">→</a>
       </div>
    </div>
    <div class="separator"></div>
    <div class="lists">
        <div class="columns" [class.full-width]="!hasItems2()">
            <div class="column" *ngFor="let item of items">
                <h3 class="lists__title">{{ item.subCategory }}</h3>
        
                <!-- Список ссылок -->
                <ul>
                    <li *ngFor="let linkItem of item.list">
                        <a class="lists__link" [href]="linkItem.href"><img class="list__icon" src="../../../assets/header/right-arrow.svg"/>{{ linkItem.linkName }}</a>
                    </li>
                </ul>
            </div> 
        </div>
        <div class="separator2" *ngIf="hasItems2()"></div>
        <div class="columns" *ngIf="hasItems2()">
            <div class="column" *ngFor="let item of items2">
                <div class="img-title">
                    <img class="left__img2" *ngIf="item.imageCategory" [src]="item.imageCategory" />
                    <h3 class="lists__title">{{ item.subCategory }}</h3>
                </div>
               
                
                <!-- Список ссылок -->
                <ul>
                    <li *ngFor="let linkItem of item.list">
                        <a class="lists__link" [href]="linkItem.href"><img class="list__icon" src="../../../assets/header/right-arrow.svg"/>{{ linkItem.linkName }}</a>
                    </li>
                </ul>
            </div> 
        </div>
       
    </div>
   <div class="riht">
     <div class="riht__content">
        <img src="../../assets/header/B1.webp" class="riht__img"/>
        <p class="riht__discription">Мы открыты для новых партнеров и интересных совместных решений</p>
        <a class="arrow" href="partners" >&rarr;</a>
     </div>
                              
   </div>
</div>