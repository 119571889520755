import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-menu-bottom',
  templateUrl: './header-menu-bottom.component.html',
  styleUrls: ['./header-menu-bottom.component.scss']
})
export class HeaderMenuBottomComponent implements OnInit {
  @Input() menuData: any[] = [];
  firstColumnData: any[] = [];
  secondColumnData: any[] = [];

  ngOnInit(): void {
    // Изначально у всех элементов showSubMenu установлено в false
    this.menuData.forEach((item) => item.showSubMenu = false);

    // Здесь заполните firstColumnData и secondColumnData нужными данными
    this.firstColumnData = this.menuData[0].items; // Например, берем данные из первого элемента массива
    this.secondColumnData = this.menuData[0].items2; // Аналогично для второго столбца

    
  }

  showSubMenu(category: any) {
     // Если у категории есть подменю, то показываем его
     if (category.items && !category.showSubMenu) {
      category.showSubMenu = true;
    }
  }
  hideSubMenu(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const relatedTarget = event.relatedTarget as Node | null;
    
    // Скрываем подменю, если курсор ушел за пределы элемента или его подменю
    if (!targetElement.contains(relatedTarget)) {
      this.menuData.forEach((item) => item.showSubMenu = false);
    }
  }
  
}
