import { Component, Output, EventEmitter } from '@angular/core';
import { SiteService } from '../site.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-menu-rotate',
  templateUrl: './header-menu-rotate.component.html',
  styleUrls: ['./header-menu-rotate.component.scss']
})
export class HeaderMenuRotateComponent {
    // Получение данных из сервиса
    mainMenu: any[] = [];
    @Output() closedMenu = new EventEmitter<void>();

  // Объявление переменных
public activeIndex: number | null = null;
private lastClickedCloseElement: HTMLElement | null = null;

constructor(private siteService: SiteService, private router: Router) {}

ngOnInit(): void {
  this.mainMenu = this.siteService.mainMenu; // инициализация данных
}

//фильтр для исключения страниц
shouldDisplayMenuItem(menuItem: any): boolean {
  // Условие для исключения определенных элементов

  if (menuItem.category === '') {
    return false;
}
  // Все остальные элементы пропускаем
  return true;
}
// Метод для открытия/закрытия категории
toggleSubCategory(menuItem: any, index: number) {
  if (menuItem.href) {
    // Переходим по ссылке
    this.onClickMenuItem(menuItem, index); // вызов метода для обработки ссылки
  } else {
    // Стандартная обработка для категорий без href
    this.activeIndex = this.activeIndex === index ? null : index;
    this.mainMenu.forEach((item, idx) => {
      if (idx !== index) {
        item.isOpen = false;
      } else {
        item.isOpen = !item.isOpen;
      }
    });

    // Закрыть все списки list при изменении категории
    this.closeAllLists();
  }
}

// Метод для открытия/закрытия списка
toggleList(event: MouseEvent, subCat: any) {
  const target = event.target as HTMLElement;

  // Если кликнули на тот же самый элемент .close, который был активен ранее,
  // то просто закройте текущий список
  if (this.lastClickedCloseElement && this.lastClickedCloseElement === target) {
    subCat.isShown = false;
    this.lastClickedCloseElement = null;
    return;
  }

  // Закроем все списки кроме текущего
  this.mainMenu.forEach((menuItem) => {
    if (menuItem.items) {
      menuItem.items.forEach((cat: { isShown: boolean }) => {
        cat.isShown = false;
      });
    }
    if (menuItem.items2) {
      menuItem.items2.forEach((cat: { isShown: boolean }) => {
        cat.isShown = false;
      });
    }
  });

  // Открываем текущий список
  subCat.isShown = true;

  // Запоминаем последний активный элемент .close
  this.lastClickedCloseElement = target;
}

// Метод для закрытия всех списков list
closeAllLists() {
  this.mainMenu.forEach((menuItem) => {
    if (menuItem.items) {
      menuItem.items.forEach((subCat: { isShown: boolean }) => {
        subCat.isShown = false;
      });
    }
    if (menuItem.items2) {
      menuItem.items2.forEach((subCat: { isShown: boolean }) => {
        subCat.isShown = false;
      });
    }
  });
}

onClickMenuItem(menuItem: any, index: number) {
  // Сброс состояния компонента
  this.resetComponentState(index);

  // Выполняем переход по ссылке
  this.router.navigate([menuItem.href]);

  // Отправляем событие о закрытии меню
  this.closedMenu.emit();
}
// Метод для сброса состояния компонента
resetComponentState(index: number) {
  // Закрытие активной категории
  this.mainMenu[index].isOpen = false;

  // Закрытие всех остальных категорий
  this.mainMenu.forEach((item, idx) => {
    if (idx !== index) {
      item.isOpen = false;
    }
  });

  // Закрытие всех списков list
  this.closeAllLists();
}
}