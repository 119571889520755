import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SiteService } from 'src/app/site.service';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate, animateChild} from '@angular/animations';

interface Page {
  id: number;
  title?: string; // Если используется
  tabProducts: {
    button: string;
    productIds: string[]; // Список id продуктов
    image: string; // Добавили поле для URL изображения
  }[];
} 
 
@Component({
  selector: 'app-products-tab',
  templateUrl: './products-tab.component.html',
  styleUrls: ['./products-tab.component.scss'],
  animations: [
    trigger('slideAnimation', [
      state('closed', style({ maxHeight: '0px', overflow: 'hidden', opacity: 0 })),
      state('open', style({ maxHeight: '300px', overflow: 'hidden', opacity: 1 })),
      transition('closed <=> open', [
        animate('300ms cubic-bezier(0.4, 0, 0.6, 1)')
      ])
    ]),
    trigger('buttonAnimation', [
      state('inactive', style({ backgroundColor: '#fff', color: '#243254' })), // Цвет фона и текста неактивного состояния
      state('active', style({ backgroundColor: '#eaeaea', color: '#243254' })), // Цвет фона и текста активного состояния
      transition('* <=> *', animate('150ms ease')) // Анимация перехода между состояниями
    ])
  ]
 
})
export class ProductsTabComponent implements OnInit {
  @Input() pageId: number = 1; // Входной параметр для передачи ID страницы
  activeTab = 1;
  currentPage: Page | undefined;
  items: any[] = []; // Для хранения списка выбранных продуктов
  products: any[]; // Массив продуктов
  isOpen: boolean[] = []; // Состояние открытых/закрытых блоков
  @Input() item: any = {};
  @Output() selectDevice = new EventEmitter<string>();

  visibleItemsCount = 2; // Количество видимых элементов
  showMoreButtonText = 'ПОКАЗАТЬ ЕЩЕ'; // Текст кнопки

  constructor(
    private siteService: SiteService,
    private router: Router
  ) {
    this.products = siteService.products; // Получаем продукты из сервиса
  }

  ngOnInit(): void {
    this.currentPage = this.siteService.pages.find((page: Page) => page.id === this.pageId);

    if (this.currentPage && this.currentPage.tabProducts.length > 0) {
      this.setActiveTab(1); // Устанавливаем первый блок активным
    }

    this.updateButtonState();
  }

  setActiveTab(tabIndex: number) {
    const productIds = this.currentPage?.tabProducts[tabIndex - 1]?.productIds || [];
    this.items = productIds.map(id => this.products.find(product => product.id === id));
    this.activeTab = tabIndex;

    // Инициализация массива isOpen
    this.isOpen = Array(this.items.length).fill(false);
    
    this.updateButtonState();
  }

  updateButtonState() {
    this.showMoreButtonText = this.visibleItemsCount >= this.items.length ? '' : 'ПОКАЗАТЬ ЕЩЕ';
  }

  toggleHiddenBlock(index: number) {
    this.isOpen[index] = !this.isOpen[index];
  }

  openProductDetails(id: string) {
    this.selectDevice.emit(id); // Отправляем событие с id устройства
  }

  openProduct(item: any) {
    this.router.navigate([], {
      queryParams: {
        id: item.id
      },
      queryParamsHandling: 'merge'
    });
  }

  onToggleVisibility() {
    if (this.visibleItemsCount < this.items.length) {
      this.visibleItemsCount = this.items.length; // Показать все элементы
      this.showMoreButtonText = 'СВЕРНУТЬ';
    } else {
      this.visibleItemsCount = 2; // Вернуться к двум элементам
      this.showMoreButtonText = 'ПОКАЗАТЬ ЕЩЕ';
    }
  }
}
