import { AfterViewInit, Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';

@Component({
  selector: 'app-program-products1chotel-one-hotel',
  templateUrl: './program-products1chotel-one-hotel.component.html',
  styleUrls: ['./program-products1chotel-one-hotel.component.scss']
})
export class ProgramProducts1chotelOneHotelComponent {
  page:any = {};
   currentPacket: any = [];
   allProducts: any = true;
   device: any = undefined; 
   products: any = []; 
   bgOpen = '';  
   bgOpenTwo = ''; 
   dynamicBackground = 'url(../../../../assets/pages/products-1C-1C-hotel/Ban1.webp)';
   constructor(
     private renderer: Renderer2, private router: Router, private route: ActivatedRoute, public site:SiteService, private openFormService: openFormService, private openFormData: openFormData
   ) {
         this.router.events.subscribe((event) => {
       if (event instanceof NavigationEnd ) {
         this.device = undefined;
         this.route.queryParams.subscribe(params => {
           if(params["id"] != undefined){
             this.device = this.site.GetDevice(params["id"]);
           }
         });
         console.log(this.device)
      }
     });
   }
   ngOnInit(){
     /*
     console.log(this.router.url);
     var id = "";
 
     this.route.params.subscribe(
       (params: Params) => {
        id = params['id'];
       }
     );
     this.page = this.site.GetCurrentPage();
     console.log(id);
     */
     this.openFormService.backgroundChanged.subscribe(() => {
       this.bgOpen = this.bgOpen ? '' : 'bg-open'; // Меняем класс
     });
     
     this.page = this.site.GetCurrentPage();
     if(this.device == undefined){
       this.products = this.site.GetProducts(this.page.products);
     }
     
     this.openFormData.backgroundChanged.subscribe(() => {
       this.bgOpenTwo = this.bgOpenTwo  ? '' : 'bg-open2'; // Меняем класс
     });
   }
 
}
 