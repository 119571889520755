<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>


<div class="section-width-100"> 

    <div class="section-width-90">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <app-interval-average></app-interval-average>


    <div class="section-width-90">
      <app-element-product-text
       [item]="this.page.text1">
      </app-element-product-text> 
   </div> 
   <app-interval-average></app-interval-average> 


   <div class="section-width-75">
      <app-element-tags [item]='[
      { text:"Модуль бронирования + Битрикс24", href:"developments/booking-module", },
   
      ]'>
    </app-element-tags>
    <!--   { text:"Обучение Битрикс24"},
      { text:"1С:Отель + Битрикс24"},
      { text:"Лицензии Битрикс24"},
      { text:"SIP телефония для Битрикс24"},
      { text:"Сопровождение Битрикс24", href:"developments/booking-module"},
      { text:"Дополнительные воронки продаж Битрикс24"},-->
   </div>
 

   <app-interval-average></app-interval-average>
    <div class="section-width-75">
       <app-accordion-all-pages ngSkipHydration *ngIf="this.page.accordion!=undefined"
          [accordions]="this.page.accordion">
       </app-accordion-all-pages>
    </div>

    <app-interval-big></app-interval-big>
    <div class="packet-decktop">
      <app-packages-tab [item]="this.page.packages" ngSkipHydration></app-packages-tab>
    </div>
      
     <div class="section-width-100 packet-mobile">
        <app-packets-mobile [item]="this.page.packages"></app-packets-mobile>
     </div> 
 
     <app-interval-big></app-interval-big>
     <div class="section-width-100 section-color">
        <div class="margins-section-accordion2__element">
           <app-accordion-all-pages-two ngSkipHydration *ngIf="this.page.accordion!=undefined"
             [accordionsT]="this.page.accordion2"></app-accordion-all-pages-two>
        </div>
    </div>

    <app-interval-mini></app-interval-mini>
    <div class="section-width-100">
       <app-footer></app-footer> 
     </div> 
 </div>



