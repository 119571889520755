import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner-content',
  templateUrl: './banner-content.component.html',
  styleUrls: ['./banner-content.component.scss']
})
export class BannerContentComponent {
 @Input() background:string = "url('../../../../assets/main/Group-3006.jpg')"
}
