<div style="width: 100%; margin-top: 40px;" >

    <div style="width: 90%; margin: auto;">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
   
   <div class="banner-content" style="margin-top: 100px;">
     <app-banner-partner></app-banner-partner>
   </div>


 <div class="trust-us">
      <div class="left">
         <app-element-product-text class="adaptive"
           [item]="this.page.text1"> 
         </app-element-product-text>
         <app-element-card-marketing   class="adaptive2"
           [item]="this.page.marketingCards">
        </app-element-card-marketing>
      </div> 
      <div class="riht">
         <app-slider-full-width></app-slider-full-width>
      </div>
   </div> 

   <div class="partner-slider">
      <app-element-partners></app-element-partners>
   </div>


  

   <div class="hi">
      <h1 class="hi__title">Некоторые наши новости!</h1>
   </div>

   <div class="partner-slider2">
      <app-card-slides ></app-card-slides>
    </div>  

    <div class="partner-slider3">
      <app-footer></app-footer> 
    </div> 
 </div>
