<div class="wrapper">
    <img class="wrapper__backgraund" [attr.src]="this.item.imageBackgraund" alt="фон"/>
    <div class="with__title-cont">
        <div class="wrapper__title-cont">
          <img class="wrapper__title-cont-img" [attr.src]="this.item.image" alt="компьютер"/>
           <p class="wrapper__title-cont-text">{{this.item.texts.text}}<br>{{this.item.texts.text2}}<br>{{this.item.texts.text3}}</p>
       </div>
    </div>
    <div class="with__subtitle-cont">
        <div class="wrapper__img-cont2">
             <img class="wrapper__img-mobile" [attr.src]="this.item.image2" alt="телефон"/>
        </div>
        <h3 class="wrapper__form-title">{{this.item.title}}<br>{{this.item.title2}}</h3>
    </div>
    <div class="wrapper__form-input-row">
        <div class="wrapper__form-input-row2">
          <input type="text" class="wrapper__form-input-row-input" id="heading" name="heading" placeholder="* введите ваш телефон" required>
          <app-button  class="contenier-content__form-button">ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</app-button>
        </div>
        
    </div>
    <div  class="wrapper__form-text-cont">
        <p class="wrapper__form-input-row-text">{{this.item.subTitle}}</p>
    </div>
    <div class="wrapper__img-cont">
       <img class="wrapper__img" [attr.src]="this.item.image2Desktop" alt="телефон" />
    </div>
</div>