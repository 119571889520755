<div class="wrapp">
   <div class="left"> 
          <div class="left__top">
             <img class="left-logo" src="assets/header/logo.webp"/>
             <div class="contenier-top-title">
                <h3 class="top-title">TripAdvance</h3>
                <p class="top-subtitle">Официальный Сайт ООО "Трип-Адванс"</p>
             </div>
          </div>
          <div class="left__bottom">
             <div class="left-titels">
                <h3 class="left-titel">Выбранная вами тема для заявки</h3> 
                <p class="left-subtitel">{{item.nameChapterForms}}</p>
             </div>
             <div class="left__bottom__img-content">
                <div class="img-title" >
                  <img class="left__bottom__img" [src]="this.item.iconForms"/>
                  <p class="left__bottom-title">{{item.titleForms}}</p>
                  <div class="name-product"  *ngIf="this.products">
                       {{this.products.name}}
                     <p class="list-product" *ngFor="let i of this.products.kits">
                       {{i.name}}  {{i.count}} * {{i.price}} =  {{i.count * i.price}}Р
                     </p>
                  </div>
                </div>  
                <img src="assets/forms/circle-arrow.svg" class="left__bottom-icon"/>
             </div>
                
          </div>
       </div> 
       <div class="riht">
         
           <form class="form-wrapp-content" #orderForm="ngForm">
              <div class="riht__titels">
                 <h3 class="riht__titel">Форма заявки</h3>
                 <p class="riht__subtitel">Заполните ваши данные</p>

                 <div class="item-content" #refItem1>
                   <p class="required-icon">*</p>
                   <div class="control">
                       <input   class="input-title" required (input)="checkInputValue($event); this.onCheckName($event);" ngModel name="name" #name="ngModel" >
                       <div class="icon-title-cont">
                          <svg  class="icon icon-deactive" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                             <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                             <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                             <g id="SVGRepo_iconCarrier"> <path d="M5 21C5 17.134 8.13401 14 12 14C15.866 14 19 17.134 19 21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                             </svg>
                         
                         <label class="icon-title " for="name" >Как вас зовут</label>
                       </div>
                   </div>
                   <small class="invalid-feedback" *ngIf="name.invalid && (name.dirty || orderForm.submitted)">Поле Имя обязательно к заполнению.</small>
                 </div>  

              </div>

              <div class="center-inputs">
               <div class="item-content" #refItem1>
                   <p class="required-icon">*</p>
                   <div class="control">
                    <input type="tel"  class="input-title"  (input)="checkInputValue($event); this.onCheckPhone($event);" ngModel name="phone" #phone="ngModel" >
                    <div class="icon-title-cont">
                      <svg class="icon icon-deactive" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                       <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                       <g id="SVGRepo_iconCarrier"> <path d="M3 5.5C3 14.0604 9.93959 21 18.5 21C18.8862 21 19.2691 20.9859 19.6483 20.9581C20.0834 20.9262 20.3009 20.9103 20.499 20.7963C20.663 20.7019 20.8185 20.5345 20.9007 20.364C21 20.1582 21 19.9181 21 19.438V16.6207C21 16.2169 21 16.015 20.9335 15.842C20.8749 15.6891 20.7795 15.553 20.6559 15.4456C20.516 15.324 20.3262 15.255 19.9468 15.117L16.74 13.9509C16.2985 13.7904 16.0777 13.7101 15.8683 13.7237C15.6836 13.7357 15.5059 13.7988 15.3549 13.9058C15.1837 14.0271 15.0629 14.2285 14.8212 14.6314L14 16C11.3501 14.7999 9.2019 12.6489 8 10L9.36863 9.17882C9.77145 8.93713 9.97286 8.81628 10.0942 8.64506C10.2012 8.49408 10.2643 8.31637 10.2763 8.1317C10.2899 7.92227 10.2096 7.70153 10.0491 7.26005L8.88299 4.05321C8.745 3.67376 8.67601 3.48403 8.55442 3.3441C8.44701 3.22049 8.31089 3.12515 8.15802 3.06645C7.98496 3 7.78308 3 7.37932 3H4.56201C4.08188 3 3.84181 3 3.63598 3.09925C3.4655 3.18146 3.29814 3.33701 3.2037 3.50103C3.08968 3.69907 3.07375 3.91662 3.04189 4.35173C3.01413 4.73086 3 5.11378 3 5.5Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                       </svg>

                      <label class="icon-title" for="name" >Введите ваш телефон</label>
                    </div>
                </div>
                <small class="invalid-feedback" *ngIf="phone.invalid && (phone.dirty || orderForm.submitted)">Поле Телефон обязательно к заполнению.</small>
                 </div> 

                 <div class="item-content" #refItem1>
                   <p class="required-icon">*</p>
                   <div class="control">
                       <input type="text" id="name" class="input-title"  required (input)="checkInputValue($event); this.onCheckEmail($event);" ngModel name="email" #email="ngModel" required>
                       <div class="icon-title-cont" >
                          <svg class="icon icon-deactive" width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                             <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                             <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                             <g id="SVGRepo_iconCarrier"> <path d="M3 8L8.44992 11.6333C9.73295 12.4886 10.3745 12.9163 11.0678 13.0825C11.6806 13.2293 12.3194 13.2293 12.9322 13.0825C13.6255 12.9163 14.2671 12.4886 15.5501 11.6333L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                             </svg>
                          <label class="icon-title icon1" for="name" >Введитеэл. почту</label>
                       </div> 
                    </div>
                    <small class="invalid-feedback" *ngIf="email.invalid && (email.dirty || orderForm.submitted)">Поле Электронная почта обязательно к заполнению.</small>
                 </div> 
              </div>

              <div class="bottom-textera">
                 <div class="item-content">
                   <p class="required-icon">*</p>
                   <div class="item-contenier">
                      <label for="main-text" class="form__label">Комментарий</label>
                      <textarea class="form__text" name="main-text" required (input)="this.onCheckComment($event);" ngModel></textarea>
                   </div>
                 </div>
              </div>

              <div class="contener-submit">
                 <app-button ngSkipHydration type="submit" (onClick)="onClick()">ОТПРАВИТЬ</app-button>
                 <div class="wrapp-checked">
                    <app-checked-foms class="margin-chek"></app-checked-foms>
                    <a href="politics-confendiality" class="policuty-href"  type="submit" >Согласие на обработку персональных данных</a>
                 </div>
              </div>
          </form>


       </div> 
 
   
  
</div> 
