import { Component } from '@angular/core';
import { SiteService } from '../../site.service';
import { ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-escort-one-c-hotel',  
  templateUrl: './escort-one-c-hotel.component.html',
  styleUrls: ['./escort-one-c-hotel.component.scss'] 
})
export class EscortOneCHotelComponent {
  currentPacket: any = [];
  activePacket:any = 0; 
  page:any = {}; 
  bgOpen = ''; 
  bgOpenTwo = '';  


  constructor(public site:SiteService, private route: ActivatedRoute, ){

  }

  ngOnInit(): void {   
    this.currentPacket = this.site.packages.training;
    this.page = this.site.GetCurrentPage();

  }

  ngAfterContentInit(){
  }

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  
  
}
