import { Component, Input, OnChanges, SimpleChanges,OnInit } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';

@Component({
  selector: 'app-element-packets-mobile',
  templateUrl: './element-packets-mobile.component.html',
  styleUrls: ['./element-packets-mobile.component.scss']
})
export class ElementPacketsMobileComponent implements OnInit {
  @Input() item: any = {};
  isOpen: boolean[] = []; // Массив для хранения состояния открытия блоков
  @Input() page:any = {};
  constructor(private openFormService: openFormService) {}

  toggleDescription(index: number) {
    // Если блок уже открыт, закроем его
    if (this.isOpen[index]) {
      this.isOpen[index] = false;
    } else {
      // Закрыть все другие блоки
      this.isOpen.fill(false);
      // Открыть текущий блок
      this.isOpen[index] = true;
    }
  }

  
  onButtonClick(pack: any) {
    this.openFormService.backgroundTop(pack.formContent);
  }

  
  ngOnInit() {
    console.log('Item in ngOnInit:', this.item);
    this.initializeIsOpenArray(this.item.blocks.length); // Инициализация массива состояний
  }

  initializeIsOpenArray(length: number) {
    this.isOpen = new Array(length).fill(false); // Все блоки закрыты изначально
    this.isOpen[0] = true; // Только первый блок открыт
  }
}
 
