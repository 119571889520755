import { Component, Input } from '@angular/core';
import { SiteService } from '../site.service';

@Component({
  selector: 'app-header-menu-top',
  templateUrl: './header-menu-top.component.html',
  styleUrls: ['./header-menu-top.component.scss']
})
export class HeaderMenuTopComponent {
 

 
}
