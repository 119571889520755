import { Component, Input } from '@angular/core';

import { openFormService } from 'src/app/servises/openForm.service';

@Component({
  selector: 'app-element-card-marketing',
  templateUrl: './element-card-marketing.component.html',
  styleUrls: ['./element-card-marketing.component.scss']
})
export class ElementCardMarketingComponent {
 @Input() item:any = {};
   @Input() page:any = {};
 
   constructor( private openFormService: openFormService) {}
   
   onButtonClick(cards:any) {
     this.openFormService.backgroundTop(cards.formContent);
   } 

}
 