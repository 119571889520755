<div class="wrapper">



    <div class="wrapper-discription">
        <div class="contents">
           <div class="contenier-content">
               <img src="../../assets/main/Logo red.svg" class="footer-logo"/>
               <p class="discription__link">TRIPADVANCE</p>
           </div>
           <div class="contenier-content__discription">
              <p class="discription">Мы стремимся к сотрудничеству с ведущими поставщиками решений в сфере путешествий и технологий объединив разработки и продукты, чтобы расширить возможности отельеров по всему миру</p>
              <p class="discription" style="margin-top: 20px;">С уважением! Команда компании Tripadvance</p>  
           </div>
        </div>
    </div>

    <div class="footer-menu">
        <app-footer-menu ></app-footer-menu>
    </div>   

    <div class="wrapper-content">
        <div class="wrapper-content__left">
            <div class="wrapper-content__column">
              <a class="content-left__text content-company">ООО Компания "ТРИП-АДВАНС"</a>
              <a class="content-left__text content-phone">+7 (861) 213-23-13</a>
              <a class="content-left__text content-email"> acc<img src="../../assets/header/email-svgrepo-com.svg" style="width: 1.2vw;margin-bottom: -3px;"/>tripadvance.ru</a>
              <a class="content-left__text content-address">353435 Краснодарский край</a>
              <a class="content-left__text content-address">г.о. город-курорт Анапа г. Анапа</a>
              <a class="content-left__text content-address">х. Усатова Балка</a>
              <a class="content-left__text content-address">ул. Красная д. 1/9 кв. 3</a>
           </div>
           <div class="wrapper-content__column">
              <a href="politics-confendiality" class="content-left__text content-personal">Условия использование персональных данных</a>
              <a class="content-left__text content-personal">Условия бронирования (модуль Tripadvance)</a>
              <div class="icon-contenier">
                <div class="icon">
                    <img  src="assets/footer/Vector01.svg" class="icon__img"/>
                </div>
                <div class="icon">
                    <img  src="assets/footer/Vector02.svg" class="icon__img"/>
                </div>
                <div class="icon">
                    <img  src="assets/footer/Vector03.svg" class="icon__img"/>
                </div>
              </div>
              <a class="text-prava">Все права защищены &copy; 2024 - 2025 Компания ООО "ТРИП-АДВАНС"</a>
           </div>
        </div>
        <div class="wrapper-content__riht">
           <iframe class="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A6786e375f6dbfbf3b73ced6373eab0fd80d0c592173c49ca10d61fd33ef5a783&amp;source=constructor"  frameborder="0"></iframe>
        </div>

    </div>

</div>
