<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>

<div sclass="section-width-100">

    <div class="section-width-90">
       <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
    </div> 
    <app-interval-average></app-interval-average>
   
   <div sclass="section-width-100">
     <app-banner-partner [page]="this.page" [item]="this.page.banerOne" ngSkipHydration></app-banner-partner> 
   </div>

   <app-interval-big></app-interval-big>
   <div class="trust-us"> 
      <div class="left">
         <app-element-product-text class="adaptive" 
           [item]="this.page.text1"> 
         </app-element-product-text>
         <app-element-card-marketing   class="adaptive2"  [page]="this.page" [item]="this.page.marketingCards" > </app-element-card-marketing>
      </div> 
      <div class="riht">
         <app-slider-full-width></app-slider-full-width>
      </div>
   </div> 

   <app-interval-average></app-interval-average>
   <div class="partner-slider">
      <app-element-partners></app-element-partners>
   </div>


   <app-interval-big></app-interval-big>
   <div class="hi">
      <h1 class="hi__title">Некоторые наши новости!</h1>
   </div>


   <app-interval-average></app-interval-average>
   <div class="section-width-90">
      <app-card-slides ></app-card-slides>
    </div>  

    <app-interval-average></app-interval-average>
    <div class="section-width-100">
       <app-footer></app-footer> 
     </div> 
 </div>
 
 <div>
   <app-modal-forms></app-modal-forms>
 </div>