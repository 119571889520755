<div class="wrapp">
    <div class="top">
        <div class="top__elements">
            <h2 class="top__title">{{this.title}}</h2>
            <h3 class="top__subtitle">{{this.subtitle}}</h3>
        </div>
    </div>
    <div class="bottom">
        <p class="bottom__title">{{this.discription}}<span class="bottom__link">{{this.titellink}}</span></p>
       
    </div>
</div>
