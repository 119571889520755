import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalDataSource = new BehaviorSubject<any>(null);
  currentModalData$ = this.modalDataSource.asObservable();

  setModalData(data: any) {
    this.modalDataSource.next(data);
  }
}