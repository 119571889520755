import { Component, Input, Output, EventEmitter } from '@angular/core';
import { openFormService } from '../../servises/openForm.service';
@Component({
  selector: 'app-element-slides-bottom-animation',
  templateUrl: './element-slides-bottom-animation.component.html',
  styleUrls: ['./element-slides-bottom-animation.component.scss']
}) 
export class ElementSlidesBottomAnimationComponent {
  @Input() item: any = {};

  constructor(private openFormService: openFormService) {}

  ngOnInit(): void {}

  onButtonClick(formContent: any) {
    if (formContent) {
      this.openFormService.backgroundTop(formContent);
    }
  }
}
