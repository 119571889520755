import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-panel-page',
  templateUrl: './panel-page.component.html',
  styleUrls: ['./panel-page.component.scss']
})
export class PanelPageComponent implements OnChanges {
  @Input() title: any = '';
  @Input() type: any = '';
  @Input() image: any = '';

  public visible: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['title'] || changes['type'] || changes['image']) {
      this.updateVisibility();
    }
  }

  private updateVisibility() {
    // Логика обновления видимости
    this.visible = true; // Или другая логика, зависящая от ваших условий
  }
}
