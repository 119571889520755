
<div class="wrapp">
   <div class="wrapp__cards" *ngFor="let item of items">
    <div class="top">
      <img class="top__icon" [src]="item.image" />
      <h3 class="top__title">{{ item.title }}</h3>
    </div>
  
    <div class="bottom" [class.hidden]="!showDescription" >
      <p class="bottom__description top__title">{{ item.description }}</p>
    </div>
  </div>

  
</div>
<div class="wrapp__button">
    <button class="rotate"  (click)="toggleDescription()"> {{ showDescription ? 'СВЕРНУТЬ ОПИСАНИЕ' : 'ПОКАЗАТЬ ОПИСАНИЕ' }}</button>
</div>

