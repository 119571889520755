<div *ngIf="currentPage">
   <!-- Проверяем длину массива currentPage.tabProducts -->
   <div class="tab" *ngIf="currentPage.tabProducts.length > 1">
     <!-- Генерируем кнопки для переключения блоков -->
     <div *ngFor="let item of currentPage.tabProducts; index as i">
       <div class="button"
          (click)="setActiveTab(i + 1)"
          [class.active]="activeTab === i + 1"
          [@buttonAnimation]="activeTab === i + 1 ? 'active' : 'inactive'"
          >
          <!-- Вставьте тег img для отображения изображения -->
          <img class="image-logo" [attr.src]="this.item.image"/>
      </div>
     </div> 
   </div>
 
   <!-- Отображаем активные блоки -->
   <div class="list" [ngClass]="{'no-scroll': items.length <= 3}" *ngIf="items.length > 0">
     <div class="list__element" *ngFor="let item of items.slice(0, visibleItemsCount); let i = index">
       <div class="list__elements">
         <!-- Открытый список -->
          <div class="list__elements-row">
             <div class="list__elements-left" (click)="toggleHiddenBlock(i)">
                <img class="image" [attr.src]="this.item.image"/>
                <div class="list__elements-name">
                   <p class="list__element-title">{{ item.name }}</p>
                   <p class="list__element-code">{{ item.id }}</p>
                </div>
             </div>
             <div class="wrapp-prise-link">
                <div class="list__elements-prise">
                   <app-price [value]="this.item.price" [size]="1.1"></app-price> 
                   <div class="separator"></div>
                </div>
                <app-button (onClick)="this.openProduct(item)">Подробнее</app-button>
             </div>
             <div class="wrapp-arrow"  [class.rotated]="isOpen[i]" (click)="toggleHiddenBlock(i)">
               <app-arrow></app-arrow>
             </div>
          </div>
       
 
            <!-- Скрытый блок -->
         <div class="list__elements-hidden" [@slideAnimation]="isOpen[i] ? 'open' : 'closed'" >
            <p class="list__elements-discription"> {{item.description}}</p>
         </div>
       </div>
       
      
  
     </div>

   </div>
 
   <!-- Кнопка "Показать еще" / "Свернуть" -->
    <div class="wrapp-bottom-rotate">
      <button class="bottom_rotate" *ngIf="showMoreButtonText" (click)="onToggleVisibility()">{{ showMoreButtonText }}</button>
    </div>
  
 </div>
 
 
 

  
  
  
  
  