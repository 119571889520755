
    <div class="bg-gray">

    </div>
    
    
    <div class="wrapp">
        
        <div class="all-cont" >
          <div class="top" *ngFor="let package of item; let i = index">
            <div class="top__buttons">
              <h3 class="top__button" [class.active-packet]="i === activeIndex"(click)="setActive(i)" >
                {{package.title}}<br>
                <span class="top__button">{{package.type}}</span>
              </h3>
            </div> 
          </div> 
        </div>
        <div *ngFor="let package of item; let i = index">
           <div class="bottom" [class.hidden]="i !== activeIndex">
              <div class="bottom__top-element">
                 <p class="bottom__discription">{{package.discriptionTop}}</p>
              </div>
              <div class="bottom__bottom-contenier">
    
                <div *ngFor="let block of package.blocks; let j = index">
                    <div class="button__title" (click)="toggleHidden(j)">
                       
                           
                        <p class="bottom__title" [class.disabled]="block?.open === false"> 
                          <span class="bottom__left-dotted" [class.disabled]="block?.open === false">&#8226;</span>
                          {{block.titleBlocks}}
                        </p>
                     
                        <p class="points"></p> 
                        <div class="wrapp-arrow" [class.rotate-down]="isBlockVisible[activeIndex][j]" >
                            <app-arrow></app-arrow>
                        </div>
                    </div>
                    
                    <div class="bottom__hidden" *ngIf="isBlockVisible[activeIndex][j]" >
                      <div  *ngFor="let subBlock of block.discriptionBlocks">
                        <p class="bottom__hidden-title" [class.disabled]="block?.open === false" >{{subBlock.description}}</p>
                      </div> 
                        
                    </div>
                </div>
             
              </div>
              <div class="footnote">
                 <p class="footnote__text">{{package.footnote}}</p>
              </div>
              <div class="attentions">
                 <div class="attentions-item" *ngFor="let block of package.attention; let j = index">
                    <img class="block-text__icon" src="../../../assets/services/warnin.svg" />
                    <!-- Проверка наличия attentionPrice -->
                    <ng-container *ngIf="block.attentionPrice && block.attentionPrice.length > 0; else noAttentionPrice">
                        <p class="attentions__text" [class.highlighted]="j === 1">{{block.text}}</p>
      
                        <!-- Дополнительный контент для attentionPrice -->
                        <ul class="extra-content">
                           <li class="extra-content__title" *ngFor="let attentionItem of block.attentionPrice">
                               {{ attentionItem.text }}
                               <div class="wrapp__price">
                                  <span class="extra-content__price">{{ attentionItem.text1 }} {{ attentionItem.text2 }}</span>
                                   <div class="extra-content__amounts">
                                      <small class="extra-content__sale" >{{ attentionItem.text3 }}</small>
                                      <strong class="extra-content__sale-before">{{ attentionItem.text4 }}</strong>
                                   </div>
                               </div>
                             
                           </li>
                        </ul>
                    </ng-container>
    
                     <!-- Блок для случая отсутствия attentionPrice -->
                    <ng-template #noAttentionPrice>
                         <p class="attentions__text">{{block.text}}</p>
                    </ng-template> 
                 </div>
              </div>  
              <div class="packet-footer__item">
                <p class="packet-footer__price">
                   <app-price [value]="'от ' + package.price" [size]="1.4"></app-price>
                </p>
                <div class="separator"></div>
                <div *ngIf="i === activeIndex">
                  <app-button  ngSkipHydration (click)="onButtonClick(package)">{{package.button}}</app-button> 
                </div>
                    
               </div> 
           </div>
        </div>
        
   </div> 


  
  
  
  
  
  
  
  
  
  
  
  
  