import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { VisibilityService } from '../../servises/Visibility.service'; // сервис обмена между компонентами авто закрытие формы
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';
import { ModalService } from '../../servises/ModalService.service'; // обмен инфы для модальных окон динамический

@Component({
  selector: 'app-modal-form2',
  templateUrl: './modal-form2.component.html',
  styleUrls: ['./modal-form2.component.scss']
})
export class ModalForm2Component { 
  
  @Input() item: any = {};
  bgOpen = '';
  showUpper = true; // Изначально показываем верхнюю часть формы
  isVisible = false;
  subscription!: Subscription;
  products: any = undefined;

  constructor(
    private visibilityService: VisibilityService,
    public site: SiteService,
    private modalService: ModalService,
    private openFormService: openFormService
  ) {
    this.subscription = this.visibilityService.hideElement$.subscribe((item: any) => {
      setTimeout(() => {
        this.isVisible = false;
      }, 7000); // Задержка в 7 секунд (7000 мс)
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe(); // Отписываемся от подписки при уничтожении компонента
    }
  }

  ngOnInit(): void {
    this.subscription = this.modalService.currentModalData$.subscribe((data) => {
      if (data) {
        this.item = data; // Получили новые данные из сервиса
        this.isVisible = true; // Открываем форму
        this.bgOpen = 'bg-open'; // Меняем класс
        this.showUpper = true; // Устанавливаем showUpper в true при каждом открытии формы
      } else {
        this.isVisible = false; // Скрываем модальное окно
      }
    });
  }

  toggleComponents() {
    this.showUpper = !this.showUpper; // Переключаем между верхним и нижним компонентами
  }

  onRemoveUpper() {
    this.showUpper = false; // Переходим к нижней части формы
  }

  close() {
    this.isVisible = false; // Закрываем всю форму
    this.bgOpen = ''; // Убираем класс
    this.modalService.setModalData(null); // Сбрасываем данные в сервисе после закрытия
  }
}
