<app-interval-mini></app-interval-mini>

<div class="dop-interval__mobile">
   <app-interval-average></app-interval-average>
</div>

<div class="section-width-90">
    <app-element-breadcrumbs [item]="this.page.breadcrumbs"></app-element-breadcrumbs>
 </div> 
 <app-interval-average></app-interval-average>


<div class="section-width-90">
    <app-block-products-two  [products]="this.site.productLic"></app-block-products-two>
 </div>
 
 <app-interval-average></app-interval-average> 
 <div class="section-width-100">
    <app-footer></app-footer> 
  </div>  

  <div  *ngIf="page.formContent">
   <app-modal-forms [item]="page.formContent"></app-modal-forms>
 </div>