<div class="wrapp"> 
    <div  class="card" *ngFor="let cards of this.item.cards">
        <div class="card-top">
            <div class="icon-cont">
                <img class="bg-img" src="assets/pages/escort-one-c-hotel/Icon Fon.webp"/>
                <img class="icon" *ngIf="cards.image!=undefined" [attr.src]="cards.image"/>
            </div>
            <h3 class="card-title" >{{cards.text}}</h3>
        </div>

 
       <div *ngIf="cards.text != undefined"> 
          
          <p class="card-discription" >{{cards.discription}}</p>      
       </div>       
       <app-button ngSkipHydration class="button" (click)="onButtonClick(cards)">ПОДРОБНЕЕ</app-button>   
    </div>
 </div>
       