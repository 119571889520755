import { AfterViewInit, Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { SiteService } from '../../site.service';
import { openFormService } from '../../servises/openForm.service';
import { openFormData } from '../../servises/openFormData.service';


@Component({  
  selector: 'app-cash-registers',
  templateUrl: './devices-cash-registers.component.html',
  styleUrls: ['./devices-cash-registers.component.scss']
})
export class DevicesCashRegistersComponent { 
  page:any = {};
  device: any = undefined;
  products: any = []; 


  constructor(
    private renderer: Renderer2, private router: Router, private route: ActivatedRoute, public site:SiteService, private openFormService: openFormService, private openFormData: openFormData
  ) {
        this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        this.device = undefined;
        this.route.queryParams.subscribe(params => {
          if(params["id"] != undefined){
            this.device = this.site.GetDevice(params["id"]);
          }
        });
     }
    });
  }
  ngOnInit(){
  
    this.page = this.site.GetCurrentPage();
    if(this.device == undefined){
      this.products = this.site.GetProducts(this.page.products);
    }
    
  }
 
}
